import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-300c88a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "working-sites h-full bg-primary-50" }
const _hoisted_2 = { class: "left-panel sidebar fixed overflow-hidden" }
const _hoisted_3 = { class: "w-full text-right" }
const _hoisted_4 = { class: "searchbar flex" }
const _hoisted_5 = {
  key: 0,
  class: "list overflow-auto"
}
const _hoisted_6 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_ChatGroupCard = _resolveComponent("ChatGroupCard")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NewChatModalDialog = _resolveComponent("NewChatModalDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PrimaryButton, {
          class: "bg-primary-900 text-white new-working-site-button",
          onClick: _ctx.showNewChatDialog
        }, {
          default: _withCtx(() => [
            _createTextVNode("Nuova Chat di Gruppo")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: "text px-4 py-3 flex-grow",
          type: "text",
          placeholder: "Cerca chat",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
        }, null, 512), [
          [_vModelText, _ctx.searchQuery]
        ])
      ]),
      (_ctx.chatGroups)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatGroups, (chat) => {
              return (_openBlock(), _createBlock(_component_ChatGroupCard, {
                "model-value": chat,
                key: chat.id
              }, null, 8, ["model-value"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_view)
    ]),
    (_ctx.showNewChatModalDialog)
      ? (_openBlock(), _createBlock(_component_NewChatModalDialog, {
          key: 0,
          onClose: _ctx.showNewChatDialog
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true)
  ]))
}