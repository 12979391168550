
import { defineComponent } from 'vue'
import ModalDialog from './ModalDialog.vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'
import Loader from '../Loader.vue'
import { IAlgoliaUserModel } from '@sultan/shared'
import { AlertType } from '@/enums'
import useVuelidate from '@vuelidate/core'
import Checkbox from '@/components/Checkbox.vue'
import { required, minLength } from '@vuelidate/validators'
import { createNamespacedHelpers } from 'vuex'

const chatHelper = createNamespacedHelpers('chat')

export default defineComponent({
  name: 'NewChatModalDialog',

  emits: ['close'],

  components: {
    ModalDialog, PrimaryButton, Loader, Checkbox
  },

  props: {
    workingSiteId: { type: String, required: true }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      name: '',
      users: [],
      defaultUsers: [],
      usersSearchFilter: '',
      userIds: [],
      loading: false
    }
  },

  async mounted () {
    this.searchUsers()
  },

  computed: {
    unselectedUsers: function (): IAlgoliaUserModel[] {
      return this.users.filter((u: IAlgoliaUserModel) => {
        return !(this.userIds as any).includes(u.id)
      })
    },
    selectedUsers: function (): IAlgoliaUserModel[] {
      return this.defaultUsers.filter((u: IAlgoliaUserModel) => {
        return (this.userIds as any).includes(u.id)
      })
    }
  },

  methods: {
    ...chatHelper.mapActions(['createChatGroup']),

    // Step 2
    addUser: function (userId: string): void {
      (this.userIds as any).push(userId)
    },
    removeUser: function (userId: string): void {
      this.userIds = this.userIds.filter((u) => u !== userId)
    },
    searchUsers: async function (): Promise<void> {
      this.users = await this.$store.dispatch('users/instantSearchUsers', {
        query: this.usersSearchFilter
      })
      this.defaultUsers = await this.$store.dispatch('users/instantSearchUsers', { })

      console.debug('searchUsers', { users: this.users })
    },

    onCreateChat: async function (): Promise<void> {
      this.loading = true
      this.v$.$touch()

      console.log(this.v$.$error)

      if (this.v$.$error) {
        this.loading = false
        // eslint-disable-next-line no-useless-return
        return
      }

      try {
        const result = await this.createChatGroup({
          name: this.name,
          memberUserIds: this.userIds
        })

        this.loading = false
        if (result === undefined) {
          await this.$store.dispatch('alerts/create', {
            message: 'creazione chat fallita',
            type: AlertType.error
          })
          return
        }

        await this.$store.dispatch('alerts/create', {
          message: 'creazione chat riuscita',
          type: AlertType.success
        })
        this.$emit('close')
      } catch (error) {
        console.error('error creating the working site', error)
        this.loading = false
        await this.$store.dispatch('alerts/create', {
          message: 'creazione chat fallita',
          type: AlertType.error
        })
      }
    }
  },

  validations () {
    return {
      name: { required, minLength: minLength(5) }
    }
  }
})
