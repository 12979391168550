
/* eslint-disable eqeqeq */
import { defineComponent } from 'vue'
import { ArchiveIcon } from '@heroicons/vue/outline'
import { PrimaryButton } from '@/components/buttons'
import { MenuButton, WorkingSiteCard } from '@/components'
import { IChatGroupModel } from '@sultan/shared'
import ChatGroupCard from '@/components/ChatGroupCard.vue'
import NewChatModalDialog from '@/components/dialogs/NewChatModalDialog.vue'
import { createNamespacedHelpers } from 'vuex'
import { IChatStoreState } from '@/interfaces'
import { getChatGroupName } from '@/utilities'

const chatHelper = createNamespacedHelpers('chat')

export default defineComponent({
  name: 'ChatsPage',

  components: {
    MenuButton, PrimaryButton, ArchiveIcon, WorkingSiteCard, ChatGroupCard, NewChatModalDialog
  },

  data () {
    return {
      showNewChatModalDialog: false,
      addWorkingSite: false,
      isShowingArchivedChats: false,
      searchQuery: ''
    }
  },

  computed: {
    ...chatHelper.mapState<IChatStoreState, any>({
      chatGroups: function (state: IChatStoreState) {
        let groups = state.chatGroups

        if (this.searchQuery != undefined && (this.searchQuery as string).trim().length > 0) {
          const s = (this.searchQuery as string).trim().toLocaleLowerCase()
          groups = groups.filter((g: IChatGroupModel) =>
            (g.name != undefined && g.name.toLocaleLowerCase().includes(s))
            || (g.lastMessage != undefined && g.lastMessage.text != undefined && g.lastMessage.text.toLocaleLowerCase().includes(s))
          )
        }

        return groups
          .sort((a, b) => {
            if (a.name == undefined || b.name == undefined) { return 0 }
            return getChatGroupName(a).localeCompare(getChatGroupName(b))
          })
          .reduce(
            (acc, group) => {
              if (acc.find(g => g.id === group.id) === undefined) {
                acc.push(group)
              }

              return acc
            }, [] as IChatGroupModel[]
          )
      }
    })
  },

  methods: {
    showNewChatDialog: function () {
      this.showNewChatModalDialog = !this.showNewChatModalDialog
    },
    search: async function (): Promise<void> {
      console.log('search', { query: this.searchQuery })
    }
  }
})
